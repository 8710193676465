import React from 'react'
import { Global } from '@emotion/core'
import { ThemeProvider, Flex, Box, css } from 'theme-ui'
import theme from '@elegantstack/flow-ui-theme/src/theme'
import pageContextProvider from '@helpers/pageContextProvider'
import { FormattedMessage, IntlProvider, addLocaleData } from 'react-intl'
import { Header } from '../Header/Header'
import simpleTransformLocale from '../Locales/simpleTransformLocale'
import { Footer } from '../Footer/Footer'
// // Locale data
import enData from 'react-intl/locale-data/en'
import zhData from 'react-intl/locale-data/zh'
// // Messages
import en from '../Locales/en-US.json'
import cn from '../Locales/cn.json'
// // initialize some things
const messages = { en, cn }

addLocaleData([...enData, ...zhData])

export const Layout = ({
  locale = 'zh-CN',
  children,
  pageContext,
  location
}) => (
  <ThemeProvider theme={theme}>
    <IntlProvider
      locale={pageContext.locale}
      messages={messages[simpleTransformLocale(pageContext.locale)]}
    >
      <pageContextProvider.Provider value={{ pageContext, location }}>
        <Flex variant='layout.layout'>
          <Global styles={css(theme => theme.global)} />
          <Header />
          {/* {console.log('locale on ', pageContext)} */}
          <Box variant='layout.body'>{children}</Box>
          <Footer />
        </Flex>
      </pageContextProvider.Provider>
    </IntlProvider>
  </ThemeProvider>
)
