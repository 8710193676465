export default {
  /** Root (body tag) */
  root: {
    WebkitFontSmoothing: `antialiased`,
    textDecoration: `none`
  },

  /** filters  */
  width: '100%',

  '@media (max-width: 580px)': {
    paddingBottom: '2rem !important'
  },
  paddingBottom: '4rem',

  '.tag': {
    cursor: 'pointer !important',
    pointerEvents: 'visible'
  },

  a: {
    textDecoration: 'none'
  },

  '.link-not-available': {
    pointerEvents: 'none',

    '&:hover': {
      /* background: #fafbfc, */
      backgroundColor: 'transparent !important'
    }
  },

  '.tableHeader': {
    padding: '19px 32px 14px',
    fontWeight: '700',
    lineHeight: '20px',
    /* background-color: #fafbfc, */
    fontSize: '12px',

    fontSize: '14px',
    /* color: #8b93a7, */
    lineHeight: '20px',
    paddingBottom: '17px',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    flexDirection: 'row',

    /* background: #fff, */
    boxShadow: '0 0 15px 0 rgb(0 0 0 / 3%)',
    borderTopLeftRadius: ' 6px',
    borderTopRightRadius: '6px',
    padding: ' 18px 30px'
  },

  '.availability, .project-tag': {
    '@media (max-width: 790px)': {
      display: 'none'
    }
  },

  '.tableRow': {
    padding: '15px 30px !important',
    /* padding: 21px 32px 15px, */
    fontSize: '13px',

    fontSize: '14px',
    /* color: #19233c, */
    lineHeight: '16px',
    padding: '17px 0',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    /* border-top: 1px solid #eceff6, */

    display: 'flex',
    justifyContent: 'space-between !important',
    alignItems: 'center',

    flexDirection: 'row',

    '&:hover': {
      /* background: #fafbfc, */
      cursor: 'pointer'
    }
  },

  '.labelWithIcon': {
    justifyContent: 'start',

    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',

    display: 'flex',
    alignItems: 'center'
  },

  '.tagsList': {
    width: '100%',
    alignItems: 'center',
    whiteSpace: 'normal',

    position: 'relative',
    display: 'inline-flex',
    /* padding: 0 16px, */
    /* background: #fff, */
    cursor: 'pointer',
    fontSize: '13px',
    marginRight: '8px',
    marginBottom: '8px',
    lineHeight: '30px',
    boxSizing: 'border-box',
    alignItems: 'center',

    marginTop: '8px'
  },

  '.projectTitle': {
    fontSize: '15px',
    fontWeight: '700'
    /* color: #19233c, */
  },

  '.chainImage': {
    borderRadius: 0,
    position: 'absolute',
    margin: '0 !important',
    width: '10px !important',
    height: '10px !important',
    right: '3px',
    top: '-5px'
  },

  '.projectIcon': {
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',

    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  }
}
