export default {
  /** Root (body tag) */
  root: {
    WebkitFontSmoothing: `antialiased`,
    textDecoration: `none`
  },

  /** filters  */
  marginBottom: '25px',
  marginTop: ' -30px',

  '.chainFilters': {
    marginBottom: '4px',
    color: '#8b93a7',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'flex-start'
  },

  '.chains': {
    width: '100%',
    alignItems: 'center',
    whiteSpace: 'normal'
  },

  '.item': {
    position: 'relative',
    borderRadius: '4px',
    display: 'inline-flex',
    padding: '0 16px',
    /* background: #fff; */
    cursor: 'pointer',
    fontSize: '13px',
    marginRight: '8px',
    marginBottom: '8px',
    lineHeight: '30px',
    boxSizing: 'border-box',
    alignItems: 'center'
  }
}
