const simpleTransformLocale = locale => {
  let localeVar
  if (locale === 'en-US' || locale === 'en') {
    localeVar = 'en'
  } else if (locale === 'zh-CN' || locale === 'cn') {
    localeVar = 'cn'
  }

  return localeVar
}

export default simpleTransformLocale
